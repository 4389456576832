import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { useNavigate, useParams, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AccessTime, Star,Warning  } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';
import { Delete as DeleteIcon } from '@mui/icons-material';
// import IconButton from '@mui/icons-material/IconButton';
import { Container, Typography, Card, CardContent, Grid, Box, Divider, Button, Modal, IconButton } from '@mui/material';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt-br'; // Import the Portuguese locale
import { url } from '../utils/constants';

moment.locale('pt-br'); // Set locale to Portuguese

const NoAnswersPlaceholder = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" textAlign="center">
    <img src='/assets/illustrations/attract-users.svg' alt="Sem Respostas" style={{ maxWidth: '300px', marginBottom: '20px' }} />
    <Typography variant="h6" color="textSecondary" gutterBottom>
      Ainda não há respostas disponíveis.
    </Typography>
    <Typography variant="body2" color="textSecondary">
      Respostas aparecerão aqui assim que forem adicionadas.
    </Typography>
  </Box>
);

const UserAnswerCard = ({ data, onClick }) => {
  // Retrieve questions from json_content object
  const questions = Object.values(data.Quiz.json_content || {});

  const avgReadingTimePerChar = 0.002; // seconds per character
  const avgThinkingTimePerQuestion = 10; // seconds per question

  // Calculate the minimum estimated time for each question
  const estimatedMinTime = questions.reduce((totalTime, questionObj) => {
    const questionLength = questionObj.question.length + (questionObj.description || '').length;
    const questionTime = questionLength * avgReadingTimePerChar + avgThinkingTimePerQuestion;
    return totalTime + questionTime;
  }, 0) / 60; // Convert to minutes

  // Check if the user's time is below the minimum estimate
  const answeredTooFast = (data.timer / 60) < estimatedMinTime;

  // console.log(estimatedMinTime)

  return (
    <Card
      sx={{
        mt: 2,
        cursor: 'pointer',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 1,
        borderRadius: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: answeredTooFast ? 'rgba(255, 235, 230, 0.6)' : 'white',
      }}
      onClick={() => onClick(data)}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
          {data.User.fullname}
        </Typography>
        <Divider sx={{ width: '100%', my: 1 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <AccessTime color="action" sx={{ mr: 1 }} />
          <Typography variant="body2" color="textSecondary">
            {(parseFloat(data.timer / 60).toFixed(2))} minutos
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <DirectionsRunIcon color="action" sx={{ mr: 1 }} />
          <Typography variant="body2" color="textSecondary">
            saiu {data.leaveCount === null ? '0' : data.leaveCount} vezes
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Star color="warning" sx={{ mr: 1 }} />
          <Typography variant="body2" color="textSecondary">
            Pontuação Total: {data.score} / {Object.keys(data.answers).length}
          </Typography>
        </Box>

        {answeredTooFast && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, color: 'error.main' }}>
            <Warning sx={{ mr: 1 }} />
            <Typography variant="body2" color="error">
              Possível uso de IA
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const QuizModal = ({ open, onClose, data }) => {
  if (!data) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ maxWidth: 700, margin: 'auto', mt: '10%', p: 4, bgcolor: 'white', borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>Respostas de {data.User.fullname}</Typography>
        
        <Box sx={{ maxHeight: 400, overflowY: 'auto', mt: 2, p: 2 }}>
          {Object.values(data.Quiz.json_content).map((q, i) => (
            <Box key={i} mt={3} sx={{ position: 'relative' }}>
              
              <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
                Pergunta {i + 1}: {q.question}
              </Typography>
              
              <MDEditor.Markdown source={q.description} />
              
              <Box mt={2}>
                {q.answers.map((a, j) => {
                  const userAnswerIndex = data.answers[i]; // User's selected answer index for question i
                  const isUserAnswer = userAnswerIndex === j; // Check if this answer is the user's selected answer
                  const isUserCorrect = isUserAnswer && a.isCorrect; // Check if user's selected answer is correct

                  return (
                    <Typography
                      key={j}
                      variant="body2"
                      sx={{
                        ml: 2,
                        color: isUserAnswer
                          ? isUserCorrect
                            ? 'green' // User's selected answer is correct
                            : 'red'   // User's selected answer is incorrect
                          : 'text.secondary',
                        fontWeight: isUserAnswer ? 'bold' : 'normal' // Bold only the user's selected answer
                      }}
                    >
                      - {a.text} {a.isCorrect && <strong>(correta)</strong>}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          ))}
        </Box>

        <Button onClick={onClose} variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

const ReportPage = ({ userAnswers, onCardClick }) => {
  if (userAnswers.length === 0) {
    return (
      <Grid item xs={12}>
        <Card style={{ height: '100%' }} mt={1}>
          <CardContent>
            <NoAnswersPlaceholder />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {userAnswers.map((userAnswer, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <UserAnswerCard data={userAnswer} onClick={onCardClick} />
        </Grid>
      ))}
    </Grid>
  );
};

const QuizReport = () => {
  const { id } = useParams();
  const [userAnswers, setUserAnswers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [filteredName, setFilteredName] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/quiz/${id}/answers`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const answersArray = Array.isArray(response.data) ? response.data : Object.values(response.data);
        console.log(answersArray)
        setUserAnswers(answersArray);

        // Extract unique user options
        const names = answersArray.map(answer => answer.User.fullname);
        setUserOptions([...new Set(names)].map(name => ({ value: name, label: name })));
      } catch (error) {
        console.error('Error fetching answers:', error);
      }
    };

    fetchAnswers();
  }, [id]);

  const handleCardClick = (data) => {
    setSelectedUser(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const filteredData = useMemo(() => 
    filteredName ? userAnswers.filter(answer => answer.User.fullname === filteredName.value) : userAnswers,
    [filteredName, userAnswers]
  );

  return (
    <Container>
      <Link to="/dashboard/quiz" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="primary" startIcon={<ArrowBackIcon />} style={{ marginBottom: '20px' }}>
          Voltar
        </Button>
      </Link>

      <Select
        options={userOptions}
        placeholder="Filtrar por operador"
        isClearable
        onChange={setFilteredName}
        style={{ marginBottom: '20px', width: '300px' }}
      />

      <ReportPage userAnswers={filteredData} onCardClick={handleCardClick} />

      {selectedUser && (
        <QuizModal open={isModalOpen} onClose={closeModal} data={selectedUser} />
      )}
    </Container>
  );
};

export default QuizReport;
