import React, { useEffect, useRef, useState } from 'react';
import { Button, Box, Container, Typography, Divider, TextField, Card, CardContent } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import EditableText from '../components/EditableText';
import Section from '../components/Section';
import { url } from '../utils/constants';


function FormEditor({ onSave }) {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [formId, setFormId] = useState(0);

  const [formName, setFormName] = useState(''); // State to hold the form name
  const [currentFormName, setCurrentFormName] = useState(''); // State to hold the form name
//   const [currentFormExpiration, setCurrentFormExpiration] = useState(''); // State to hold the form name
  const [expirationDate, setExpirationDate] = useState('');


  // Function to update form name
  const handleFormNameChange = (newFormName) => {
    setFormName(newFormName);
    setCurrentFormName(newFormName)
  };
    
  const buttonRef = useRef(null);

//   useEffect(() => {
//     if (buttonRef.current && sections.length === 0) {
//         buttonRef.current.click();  // Simulate the button click
//     }
//   }, []);  // Empty array ensures it runs only once after the initial rende

  // Validate expiration date to be at least 5 minutes ahead of current time
  const validateExpirationDate = (date) => {
    const currentTime = new Date();
    const selectedDate = new Date(date);
    const timeDiff = selectedDate - currentTime;
    if (timeDiff < 5 * 60 * 1000) { // 5 minutes in milliseconds
      return false;
    }
    return true;
  };


const handleExpirationDateChange = (e) => {
    const selectedDate = e.target.value;
    if (validateExpirationDate(selectedDate)) {
      setExpirationDate(selectedDate);
    } else {
      alert("A data de expiração deve ser no mínimo 5 minutos à frente da hora atual.");
    }
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/form/edit/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formArray = Array.isArray(response.data.json_content)
          ? response.data.json_content
          : Object.values(response.data.json_content);

        document.title = response.data.name
        setCurrentFormName(response.data.name)
        // Format validAt for datetime-local
        const validAt = response.data.validAt
            ? new Date(response.data.validAt).toISOString().slice(0, 16)
            : '';

        setExpirationDate(validAt); // Set formatted date
        console.log(response.data)

        setFormData(formArray);
        setSections(formArray)
        setFormId(response.data.id)
        console.log(formArray)
      } catch (error) {
        navigate('/404');
        console.error('Error fetching forms:', error);
      }
    };

    fetchForm();
  }, [id, navigate,formId]);

  const [sections, setSections] = useState(
   []
  );

  const addSection = () => {
    setSections([...sections, { title: '', inputs: [], isCollapsed: false }]);
  };

  const updateSection = (index, newSection) => {
    const updatedSections = [...sections];
    updatedSections[index] = newSection;
    setSections(updatedSections);
  };

  const toggleCollapse = (index) => {
    const updatedSections = [...sections];
    updatedSections[index].isCollapsed = !updatedSections[index].isCollapsed;
    setSections(updatedSections);
  };

  const deleteSection = (index) => {
    if (window.confirm('Tem certeza que deseja apagar a seção')) {
      const updatedSections = sections.filter((_, i) => i !== index);
      setSections(updatedSections);
    }
  };

  const saveForm = async () => {
    // onSave(sections);
    const formData = {
      json_content: {...sections},
      name: formName,
      status: true,
      is_public: false,
      allow_multiple: true,
      valid_at: expirationDate,  // Add expiration date to form data
    }


    console.log(formData)

    // return;

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`, // Send the token in the header
    }

    try{
      const response = await axios.put(`${url}/update-form/${id}`, formData, {headers: {...headers}});

      if(response.status === 200){
        await Swal.fire({
          title: 'Formulário atualizado com sucesso',
          icon: 'success',
          text: 'Você será redirecionado para a página de formulários',
          timer: 4000
        })

        navigate('/dashboard/forms')
      }
    }catch (error) {
      await Swal.fire({
        title: 'Falha ao processar solicitação',
        icon: 'error',
        text: 'Ocorreu um erro ao enviar seu pedido, tente novamente ou contacte o administrador do sistema',
        timer: 4000
      })
    }

  };

  return (
    <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 4 }}>
            Editar formulário
        </Typography>

        <Divider sx={{ mb: 4 }} />

        {
            currentFormName != '' && 
            <EditableText onFormNameChange={handleFormNameChange} defaultText={currentFormName} />
        }

      {/* Settings Section */}
      <Card sx={{ mt: 4, mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Configurações do Formulário
          </Typography>

          <TextField
            label="Data de Expiração"
            type="datetime-local"
            value={expirationDate}
            onChange={handleExpirationDateChange}
            sx={{ mb: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CardContent>
      </Card>

        <Box sx={{ mt: 4 }}>
        {sections.map((section, index) => (
            <Section
            key={index}
            section={section}
            updateSection={(newSection) => updateSection(index, newSection)}
            toggleCollapse={() => toggleCollapse(index)}
            deleteSection={() => deleteSection(index)} // Pass delete handler
            />
        ))}
            {/* <br /> */}
            
            <Button ref={buttonRef} variant="contained" style={{background: 'rgb(159, 170, 181)'}} onClick={addSection} sx={{mt: 2}}>
                Adicionar seção
            </Button>
            <div style={{clear: 'both'}} />
            {
                sections.length > 0 &&
                <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={saveForm}>
                    Atualizar
                </Button>
            }
        </Box>
    </Container>
  );
}

export default FormEditor;