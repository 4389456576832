import { useEffect, useState } from 'react';
import { Navigate, useParams  } from 'react-router-dom';
import { validateToken, validateRole, validatePublic, validatePublicQuiz, validatePublicForm } from '../authService';

const AuthGuard = ({ children, requiredRole, page ,nextUrl = null }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isExpired, setIsExpired] = useState(null);
  const token = localStorage.getItem('token');

  const { id } = useParams();



  nextUrl = nextUrl === null ? window.location.href : nextUrl; 

  useEffect(() => {

    console.log('authguard', id, page)

    const checkPublic = async () => {
      if(page === 'FormRender' && id){ //those pages can allow private either public agents, so we need check them and make sure id is defined
        const isPublicForm = await validatePublicForm(id);
        
        return isPublicForm.is_public
      }

      if(page === 'QuizRender' && id){ //those pages can allow private either public agents, so we need check them and make sure id is defined
        const isPublicQuiz = await validatePublicQuiz(id);
        
        return isPublicQuiz.is_public
      }

      return false;
    }

    const checkAuthorization = async () => {

      const isPublic = await checkPublic()

      console.log(isPublic)
      

      if(isPublic === true){
        //as we ensured the link is  public, we shall redirect the user to the destine page by setting the state
        setIsExpired(false)
        setIsAuthorized(true);
        console.log('entered here')
      }else{
        if (!token) {
          // Capture the current URL
          document.cookie = `nextUrl=${encodeURIComponent(nextUrl)}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`; // Set cookie
          document.location.href = '/login'
        }
  
        const { isAuthorized: tokenIsValid, isExpired: tokenIsExpired, role } = await validateToken(token);
        setIsExpired(tokenIsExpired); // Set the expiration state
  
        // Validate role if token is valid
        if (tokenIsValid) {
          const roleIsValid = validateRole(role, requiredRole);
          setIsAuthorized(roleIsValid);
        } else {
          setIsAuthorized(tokenIsValid);
        }
      }
    };

    // checkPublic();
    checkAuthorization();
  }, [token, requiredRole, id]);

  if (isAuthorized === null || isExpired === null) {
    return <div>Loading...</div>;
  }

  if (isExpired === true) {
    document.cookie = `nextUrl=${encodeURIComponent(nextUrl)}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`; // Set cookie

    return <Navigate to='/login' />;
  }

  if (!isAuthorized) {
    document.cookie = `nextUrl=${encodeURIComponent(nextUrl)}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`; // Set cookie

    return <Navigate to='/403' />;
  }

  return children;
};

export default AuthGuard;
