import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Box, Container, Typography, Divider, Chip, Stack, IconButton, Menu, MenuItem, Modal, Tooltip  } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';
import { Public, Lock, MoreVert } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Iconify from '../components/iconify';
import { url } from '../utils/constants';

function Forms() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRowId, setMenuRowId] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [copyStatus, setCopyStatus] = useState('default'); // 'default' or 'copied'
  
  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(rowId);
  };

  const handleModalClose = () => {
    setShareModalOpen(false);
    setCopyStatus('default'); // Reset the status when modal closes
  };

  const handleShare = (rowId) => {
    const formUrl = `${window.location.origin}/schema/form/${rowId}`;
    setShareUrl(formUrl);
    setShareModalOpen(true);
    handleMenuClose();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopyStatus('copied');
      setTimeout(() => setCopyStatus('default'), 3000); // Reset after 3 seconds
    });
  };

  const performAction = async (formId, action) => {
    try {

      const token = localStorage.getItem('token');

      const headers = {
        Authorization: `Bearer ${token}`, // Send the token in the header
      }

      const response = await axios.post(`${url}/api/forms/action`, {form_id: formId, action: action}, {headers: {...headers}});

      if (response) {
        // Optionally, refresh the form list or update the state
        Swal.fire({
          title: 'Ação realizada com sucesso',
          icon: 'success'
        })

        fetchForms()
        console.log('Action performed successfully');
      } else {
        console.error('Failed to perform action');
      }
    } catch (error) {
      console.error('Error performing action:', error);
    }
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };



  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'formname',
      headerName: 'Formulário',
      width: 360,
      renderCell: (params) => (
        <Link to={`/dashboard/report/${params.row.id}`} style={{ textDecoration: 'none', color: 'blue' }}>
          {params.row.name}
        </Link>
      ),
    },
    {
      field: 'fullname',
      headerName: 'Criado por',
      width: 180,
      renderCell: (params) => (
        <span>{params.row.User.fullname}</span>
      ),
    },
    { 
      field: 'sent', 
      headerName: 'Envios realizados', 
      type: 'number', 
      width: 160, 
      renderCell: (params) => (
        <Chip
          label={params.row.answerCount}
          color={'default'}
          variant="outlined"
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <Chip
          label={params.row.status ? 'ativo' : 'desativado'}
          color={params.row.status ? 'success' : 'default'}
          variant="outlined"
        />
      ),
    },
    {
      field: 'validAt',
      headerName: 'Valido até',
      width: 160,
      renderCell: (params) => (
        <span>{params.row.validAt ? params.row.validAt : 'não expira'}</span>
      ),
    },
    {
      field: 'is_public',
      headerName: 'Público',
      description: 'Público aberto não necessita realizar login',
      renderCell: (params) => (
        params.value ? <Public /> : <Lock />
      ),
    },
    {
      field: 'allow_multiple',
      headerName: 'Permite múltiplos envios',
      width: 180,
      renderCell: (params) => (
        <Chip
          label={params.value ? 'sim' : 'não'}
          color={params.value ? 'success' : 'default'}
          variant="outlined"
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => handleMenuClick(event, params.row.id)}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && menuRowId === params.row.id}
            onClose={handleMenuClose}
          >
            <MenuItem>
            <a href={`/dashboard/edit-form/${params.row.fuid}`} rel='noreferrer' style={{ textDecoration: 'none', color: 'inherit' }}>
                Editar
              </a>
            </MenuItem>
            <MenuItem onClick={() => { /* Implement view functionality */ handleMenuClose(); }}>
              <a href={`/schema/form/${params.row.fuid}`} target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: 'inherit' }}>
                Visualizar
              </a>
            </MenuItem>
            <MenuItem onClick={() => handleShare(params.row.fuid)}>Compartilhar</MenuItem>
            {params.row.status ? (
              <MenuItem onClick={() => { /* Implement disable functionality */ performAction(params.row.id, 'disable') }}>Desabilitar</MenuItem>
            ) : (
              <MenuItem onClick={() => { /* Implement enable functionality */ performAction(params.row.id, 'enable') }}>Habilitar</MenuItem>
            )}
            <MenuItem onClick={() => { /* Implement public/private functionality */ performAction(params.row.id, params.row.is_public ? 'makePrivate' : 'makePublic') }}>
              {params.row.is_public ? 'Tornar Privado' : 'Tornar Público'}
            </MenuItem>
            <MenuItem onClick={() => { /* Implement allow/revoke multiple submissions */ performAction(params.row.id, params.row.allow_multiple ? 'revokeMultiple' : 'allowMultiple') }}>
              {params.row.allow_multiple ? 'Revogar Múltiplos' : 'Permitir Múltiplos'}
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const [forms, setForms] = useState([]);

  const fetchForms = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${url}/api/forms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setForms(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title> Formulários | Atel Forms </title>
      </Helmet>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Formulários criados
        </Typography>
        <Button
          onClick={() => { document.location.href = '/dashboard/add-form'; }}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Novo formulário
        </Button>
      </Stack>

      <Paper sx={{ height: 330, width: '100%' }}>
        <DataGrid
          rows={forms}
          columns={columns}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>

      <Modal open={shareModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>
            Compartilhar Formulário
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, wordBreak: 'break-word' }}>
            {shareUrl}
          </Typography>
          <Tooltip title={copyStatus === 'default' ? 'Copiar para a área de transferência' : ''}>
            <Button
              variant="contained"
              sx={{
                bgcolor: copyStatus === 'copied' ? 'success.main' : 'primary.main',
                color: 'white',
              }}
              startIcon={copyStatus === 'copied' ? <CheckCircleIcon /> : <ContentCopyIcon />}
              onClick={handleCopyToClipboard}
            >
              {copyStatus === 'copied' ? 'Copiado!' : 'Copiar'}
            </Button>
          </Tooltip>
        </Box>
      </Modal>

    </Container>
  );
}

export default Forms;
