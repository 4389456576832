// authService.js

import axios from "axios";
import { url } from "./utils/constants";

// export const validateToken = async (token, requiredRole) => {
//   try {
//     const response = await axios.post(`${url}/api/auth/validate-token`, {
//       token,
//       requiredRole,
//     });
//     return response.data.isAuthorized; // Assuming the backend returns an 'isAuthorized' field
//   } catch (error) {
//     console.error('Token validation error:', error);
//     return false;
//   }
// };

export const validateToken = async (token) => {
    try {
      const response = await axios.post(`${url}/api/auth/validate-token`, {
        token,
      });
      return response.data; // Return the full response data
    } catch (error) {
      console.error('Token validation error:', error);
      return { isAuthorized: false, isExpired: false }; // Handle error gracefully
    }
  };

  export const validatePublicForm = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${url}/api/form/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Token validation error:', error);
      return { isAuthorized: false, isExpired: false }; // Handle error gracefully
    }
  };


  export const validatePublicQuiz = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${url}/api/quiz/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Token validation error:', error);
      return { isAuthorized: false, isExpired: false }; // Handle error gracefully
    }
  };
  
  // New function to validate roles
  export const validateRole = (decodedRole, requiredRole) => {
    const rolesArray = Array.isArray(requiredRole) ? requiredRole : [requiredRole];
    
    return rolesArray.includes('any') || rolesArray.includes(decodedRole);
  };

export const getUserName = () => {
    return localStorage.getItem('name') || 'visitante'
}

export const getUserEmail = () => {
    return localStorage.getItem('email') || 'visitante'
}

export const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    document.location.href = '/login'; // Redirect to login page or home page
  };